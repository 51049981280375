import React, { useState } from 'react';
import '../css/QuestionAnswserComponent.css'
function QuestionAnswerComponent({ lastExchange ,onSubmitAnswers, locale }) {

    const [answers, setAnswers] = useState([]);
    const messages = {
        en: {

            buttonText: 'Answer',
            questionLabel: 'Question'
        },
        fr: {
            buttonText: 'Répondre',
            questionLabel: 'Question'
        },
        es: {
            buttonText: 'Respuesta',
            questionLabel: 'Pregunta'
        }
    };
    const handleAnswerChange = (questionIndex, answer) => {
        const updatedAnswers = [...answers];
        updatedAnswers[questionIndex] = { questionIndex, answer };
        setAnswers(updatedAnswers);
    };

    const handleSubmit = () => {
        const submittedAnswers = answers.map(answer => answer ? answer.answer : null);
        onSubmitAnswers(submittedAnswers);
        for (let i = 0; i < lastExchange.questions.length; i++) {
            const answerObj = answers[i];
          /*  if (answerObj) {
                console.log(`Réponse à la question ${i+1}: ${answerObj.answer}`);
                // Ici, vous pouvez ajouter la logique pour envoyer la réponse au serveur ou la traiter davantage
            } else {
                console.log(`Pas de réponse à la question ${i+1}`);
            }*/
        }
    };

    return (

        <div key='toto'>
            {lastExchange && lastExchange.questions && lastExchange.questions.length >0 && lastExchange.questions.map((question, index) => (
                <div key={index} className="input-text-container">
                    <p><b>{messages[locale].questionLabel} :</b> {question}</p>
                    <p><i style={{ fontSize: 'smaller' }}>{lastExchange.questionsDoc[index]}</i></p>
                    <input className="input-text-style"
                        type="text"
                        value={(answers[index] && answers[index].answer) || ''}
                        onChange={(e) => handleAnswerChange(index, e.target.value)}
                    />
                </div>
            ))}
            {lastExchange.questions && lastExchange.plan.length===0 && <button className="button-style"  onClick={handleSubmit}>{messages[locale].buttonText}</button>}
        </div>
    );
}

export default QuestionAnswerComponent;
