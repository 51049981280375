import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import '../css/InfoModal.css'

Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.75)';

const InfoModal = ({location}) => {


    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('utilisation');
    const [locale, setLocale] = useState(location);
    const messages = {
        en: {
            utilisation: (<div>
                <p> After payment, a confirmation email will be sent to you.<br/>
                    <b>This email will contain your transaction key</b> which you will need to keep because
                    this will be asked if you need to be in contact with our support service.</p>
                <b>Be sure to keep this key in a safe place. </b>
                <hr/>
                <p style={{color: 'red'}}><b>Please note, the delay between each question may take a few
                    seconds, please be patient.<br/></b></p>
                <p style={{color: 'red'}}>Do not go back in navigation.<br/></p>
                In case you exit the process by mistake, please click on the link in
                the confirmation email.<br/>
                The link will be disabled after generating the pdf.
                <hr/>
                <b>Step 1:</b><br/> Enter what you want, here are some typical examples:<br/>
                <i>-I want to stop smoking gently in 3 months.<br/>
                    -I want to reduce my consumption by half in 2 weeks</i><br/>
                Express yourself as you naturally do...<b>in the language you
                desire</b> (this regardless of the language chosen for the interface, <b>the answers, the
                questions as well as your plan will be in the language in which you express yourself</b>)
                <hr/>
                <b>Step 2:</b><br/> A series of questions about you will be submitted to you in order to develop a
                plan to achieve your goal in the best conditions.<br/>
                After each answer 2 pieces of advice will be given to you, these will also be personalized in
                based on the answer you provided.

                Answer the questions as naturally and honestly as possible so that the plan
                is adjusted as best as possible.
                <hr/>
                <b>Step 3:</b><br/>Your plan is generated and displayed to you on screen.
                <br/>
                At the same time it is downloaded to your computer (computer/phone, etc.).
                <br/>You will find it in the folder where your downloads are usually under the
                name<b> "plan.pdf"</b>.
                <br/>
                The plan will contain (in your language):
                <br/>
                -The <b>personalized advice</b> provided to you throughout your journey.<br/>
                -<b>Personalized tips </b>to maintain your motivation.<br/>
                -Your<b>personalized plan</b> to achieve your goal.
                <br/><br/>
                And also <b>a bonus</b> (in the language chosen for the interface
                (English/French/Spanish)
                <hr/>
                <b>
                    We thank you for your trust and wish you good luck in your
                    course...
                    <br/>
                    Remember that motivation is your best friend.</b>
                <br/>
                <br/>

            </div>),
            confident: (<div className='confid_container'>
                <p>We will only ask you <b>for your e-mail address</b> in order to send you
                    your payment confirmation and recovery link in case of technical problem
                    while establishing your plan.</p>
                <p>Your data including the answers you provide <b>neither kept nor sold</b>.</p>
                <p>All information you communicate will <b>remain completely anonymous.</b></p>

            </div>),
            cvg: (<div>Consult our conditions of sale : <a
                href="https://tabacfinisher.com/terminos-y-condiciones-de-compra-tabacfinisher/" target="_blank">CVG</a>
            </div>),
            btnUse: ('How to...'),
            btnConf: ('Confidentiality'),
            btnCVG: ('Sell conditions'),
            btnClose: 'close'
        },
        fr: {
            utilisation: (<div>
                <p> Après paiement, un e-mail de confirmation vous sera envoyé.<br/>
                    <b>Cet e-mail contiendra votre clé de transaction</b> qu'il vous faudra conserver car
                    celle-ci vous sera demandée si vous deviez être en contact avec notre service support.</p>
                <b>Veillez à conserver cette clé en lieu sûr. </b>
                <hr/>
                <p style={{color: 'red'}}><b>Attention, le délais entre chaque questions peut pendre quelques
                    secondes, soyez patient svp.<br/></b></p>
                <p style={{color: 'red'}}>Ne revenez pas en arrière dans la navigation.<br/></p>
                Au cas ou vous quitteriez le processus par erreur, merci de cliquez sur le lien présent dans
                l'e-mail de confirmation.<br/>
                Le lien sera désactivé après la génération du pdf.
                <hr/>
                <b>Étape 1:</b><br/> Entrez ce que vous désirez, voici quelques exemples type:<br/>
                <i>-Je souhaite arrêter de fumé en 3 mois en douceur.<br/>
                    -Je souhaite diminuer ma consommation de moitié en 2 semaines</i><br/>
                Exprimez-vous comme vous le faite naturellement...<b>dans la langue que vous
                désirez</b> (celle-ci indifféremment de la langue choisie pour l'interface, <b>les réponses, les
                questions ainsi que votre plan seront dans la langue dans laquelle vous vous exprimez</b>)
                <hr/>
                <b>Étape 2:</b><br/> Une série de questions vous concernant vous sera soumis afin d'élaborer un
                plan pour atteindre votre objectif dans les meilleurs conditions.<br/>
                Après chaque réponse 2 conseils vous seront donnés, ceux-ci seront également personnalisés en
                fonction de la réponse que vous avez fourni.

                Répondez aux question le plus naturellement et le plus honnêtement possible afin que le plan
                soit ajusté au mieux.
                <hr/>
                <b>Étape 3:</b><br/>Votre plan est généré et vous est affiché à l'écran.
                <br/>
                Dans le même temps celui-ci est téléchargé sur votre poste (ordinateur/téléphone...).
                <br/>Vous le trouverez dans le dossier où vos téléchargement sont habituellement sous le
                nom<b> "plan.pdf"</b>.
                <br/>
                Le plan contiendra (dans votre langue):
                <br/>
                -Les <b>conseils personnalisés</b> qui vous ont été fourni tout au long de votre parcours.<br/>
                -Des <b>astuces personnalisées </b>pour conserver votre motivation.<br/>
                -Votre<b> plan personnalisé</b> pour atteindre votre objectif.
                <br/><br/>
                Et également <b>un bonus</b> (dans la langue choisie pour l'interface
                (anglais/français/espagnol)
                <hr/>
                <b>
                    Nous vous remercions de votre confiance et vous souhaitons bonne chance dans votre
                    parcours...
                    <br/>
                    N'oubliez pas que la motivation est votre meilleure amie.</b>
                <br/>
                <br/>

            </div>)
            ,
            confident: (<div className='confid_container'>
                <p>Nous ne vous demanderons <b>uniquement votre adresse e-mail</b> afin vous faire parvenir
                    votre confirmation de paiement et le lien de récupération en cas de problème technique
                    durant l'établissement de votre plan.</p>
                <p>Vos données y compris les réponses que vous fournirez <b>ni conservées, ni vendues</b>.</p>
                <p>Toues les informations que vous communiquerez <b>resterons totalement anonymes.</b></p>

            </div>),
            cvg: (<div>Consulter nos conditions de ventes : <a
                href="https://tabacfinisher.com/terminos-y-condiciones-de-compra-tabacfinisher/" target="_blank">CVG</a>
            </div>),
            btnUse: ('utilisation...'),
            btnConf: ('Confidentialité'),
            btnCVG: ('Conditions de vente'),
            btnClose: 'fermer'
        },
        es: {
            utilisation: (<div>
                <p> Después del pago, se te enviará un correo electrónico de confirmación.<br/>
                    <b>Este correo electrónico contendrá tu clave de transacción</b> que deberás conservar por
                    si necesitas ponerte en contacto con nuestro servicio de soporte.</p>
                <b>Asegúrate de guardar esta clave en un lugar seguro. </b>
                <hr/>
                <p style={{color: 'red'}}><b>Ten en cuenta que el tiempo entre cada pregunta puede tardar algunos
                    segundos, ten paciencia.<br/></b></p>
                <p style={{color: 'red'}}>No retrocedas en la navegación.<br/></p>
                En caso de que salgas del proceso por error, haz clic en el enlace
                del correo electrónico de confirmación.<br/>
                El enlace se desactivará después de generar el pdf.
                <hr/>
                <b>Paso 1:</b><br/> Introduce lo que quieras, aquí tienes algunos ejemplos típicos:<br/>
                <i>-Quiero dejar de fumar gradualmente en 3 meses.<br/>
                    -Quiero reducir mi consumo a la mitad en 2 semanas</i><br/>
                Exprésate como lo haces naturalmente...<b>en el idioma que
                desees</b> (esto independientemente del idioma elegido para la interfaz, <b>las respuestas, las
                preguntas así como tu plan estarán en el idioma en el que escribas.</b>)
                <hr/>
                <b>Paso 2:</b><br/> Se harán una serie de preguntas sobre ti para proporcionarte una
                planificación que te per,ita alcanzar tu objetivo en las mejores condiciones.<br/>
                Después de cada respuesta se te darán 2 consejos, estos también serán personalizados de
                acuerdo con la respuesta que proporcionaste.

                Contesta las preguntas de la manera natural y honesta para que el plan
                se ajuste lo mejor posible.
                <hr/>
                <b>Paso 3:</b><br/>Tu plan se generará y aparecerá en la pantalla.
                <br/>
                Al mismo tiempo se descargará en tu ordenador (ordenador/teléfono, etc.).
                <br/>Lo encontrarás en la carpeta donde normalmente se encuentran tus descargas bajo el título
                nombre<b> "plan.pdf"</b>.
                <br/>
                El plan incluirá (en tu idioma):
                <br/>
                -El <b>asesoramiento personalizado</b> que te brindaremos durante todo tu viaje.<br/>
                -<b>Consejos personalizados </b>para mantener tu motivación.<br/>
                -Tu<b>plan personalizado</b> para lograr tu objetivo.
                <br/>><br/>
                Y también <b>un bono</b> (en el idioma elegido para la interfaz
                (inglés/francés/español)
                <hr/>
                <b>
                    Te agradecemos tu confianza y te deseamos mucha suerte en tu
                    camino...
                    <br/>
                    Recuerda que la motivación es tu mejor amiga.</b>
                <br/>
                <br/>

            </div>),
            confident: (<div className='confid_container'>
                <p>Solo te pediremos <b>tu dirección de correo electrónico</b> para poder enviarte
                    tu confirmación de pago y enlace de recuperación en caso de problema técnico
                    mientras estableces tu plan.</p>
                <p>Tus datos, incluidas las respuestas que proporcionas, <b>ni se conservan ni se venden</b>.</p>
                <p>Toda la información que proporciones <b>permanecerá completamente anónima.</b></p>

            </div>),
            cvg: (<div>Consulta nuestras condiciones de venta : <a
                href="https://tabacfinisher.com/terminos-y-condiciones-de-compra-tabacfinisher/" target="_blank">CVG</a>
            </div>),
            btnUse: ('Utilización...'),
            btnConf: ('Confidencialidad'),
            btnCVG: ('Condición de venta'),
            btnClose: 'Cerrar'
        }
    };
    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    return (
        <div>
            <img className='img_info' src="/static/media/info.png" onClick={openModal} alt="Info"
                 style={{cursor: 'pointer'}}/>

            <Modal
                ariaHideApp={false}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Info Modal"
                className="modal-contentp" // Ajout de la classe pour les styles
            >
                <div className='language'>
                    <img className='' src="/static/media/EN.png" width='10%' onClick={() => {
                        setLocale('en')
                    }} alt="Info"
                         style={{cursor: 'pointer'}}/>
                    <img className='' src="/static/media/FR.png" width='10%' onClick={() => {
                        setLocale('fr')
                    }} alt="Info"
                         style={{cursor: 'pointer'}}/>
                    <img className='' src="/static/media/ES.png" width='10%' onClick={() => {
                        setLocale('es')
                    }} alt="Info"
                         style={{cursor: 'pointer'}}/>
                </div>
                <div className='btn_container'>
                    <button className='btn_bg'
                            onClick={() => setActiveTab('utilisation')}>{messages[locale].btnUse}</button>
                    <button className='btn_bg'
                            onClick={() => setActiveTab('confidentialité')}>{messages[locale].btnConf}</button>
                    <button className='btn_bg'
                            onClick={() => setActiveTab('conditions')}>{messages[locale].btnCVG}</button>
                </div>

                <div>
                    {activeTab === 'utilisation' && messages[locale].utilisation}
                    {activeTab === 'confidentialité' && messages[locale].confident}
                    {activeTab === 'conditions' && messages[locale].cvg}
                </div>
                <div className='btn_cl'>
                    <button className='btn_bg ' onClick={closeModal}>{messages[locale].btnClose}</button>
                </div>
            </Modal>
        </div>
    );
};

export default InfoModal;
