import React from 'react';
import '../css/card.css';
import ReactMarkdown from "react-markdown"; // CSS pour le style de la carte

function Card({ title, text ,id}) {

    function isLight(color) {
        // Convertir la couleur hexadécimale en valeurs RGB
        const hex = color.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        // Calculer la luminance
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5;
    }

// Utiliser la fonction pour définir la couleur du texte
    const backgroundColor = `hsl(${id % 360}, 70%, 80%)`;
    const textColor = isLight(backgroundColor) ? 'black' : 'white';

    return (
        <div className="card StoryIt_card" style={{ backgroundColor}}>
            <div className="StoryIt_cardDecoration"> {/* Conteneur pour la décoration */}
                <h2 className="StoryIt_cardTitle">{title}</h2>
                {text}
            </div>
            {/* Ajouter ici d'autres éléments HTML pour les décorations spécifiques */}
        </div>
    );
}

export default Card;

